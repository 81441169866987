import React, { useState } from "react";
import "./App.css";
import SendLoveModal from "./components/SendLoveModal";
import SelectAddressFromWallet from "./components/SelectAddressFromWallet";
import SuccessModal from "./components/SuccessModal";

function App() {
  const [modalOpen, setModalOpen] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [blockHash, setBlockHash] = useState("");

  const openRewardWallet = () => {
    window.open("https://avascan.info/blockchain/c/address/0xE135cA395E107Da09e65C0527ae33D8F56997Fe5", "_blank");
  };

  return (
    <>
      <SendLoveModal modalOpen={modalOpen} setModalOpen={setModalOpen} setSuccessModalOpen={setSuccessModalOpen} setBlockHash={setBlockHash}  />
      <div className="bg-body max-w-[1052px] mx-auto text-white min-h-screen">
        <header className="py-4 px-6 flex items-center justify-between">
          <div className="flex gap-2">
            <img src="/assets/logo.svg" alt="logo" />
            <div className="">
              <h1 className="text-2xl font-bold">Cupid</h1>
              <p className="text-sm">The Love Game</p>
            </div>
          </div>
          <SelectAddressFromWallet />
        </header>

        <section className="py-12 px-6 flex flex-col">
          <div className=" flex items-center justify-between gap-5">
            <div>
              <h2 className="text-5xl font-bold mb-4">
                Write a note, Send some $LOVE, and join the race to win 999
                $AVAX
              </h2>
              <p className="text-lg mb-4">Love is all that matters! ❤️ </p>
            </div>
            <img src="/assets/hero.png" alt="love" width={450} />
          </div>
          <button
            onClick={() => setModalOpen(true)}
            className="px-6 py-1 bg-secondary rounded-lg text-black shadow-xl font-bold hover:scale-105 mx-auto flex items-center gap-2 mt-10"
          >
            Activate Cupid{" "}
            <img src="/assets/logo.svg" width={20} alt="cupid-logo" />
          </button>
        </section>

        <section className="py-8 px-6 flex flex-col">
          <div className="text-center relative p-5 bg-gradient rounded-xl text-black">
            <div className="absolute top-0 right-0">
              <img
                src="/assets/coming-soon.png"
                alt="love-banner"
                width={120}
                height={100}
              />
            </div>
            <h3 className="text-2xl font-bold mb-4">$LOVE Note Voting</h3>
            <p className="text-lg max-w-[500px] mx-auto my-5">
              <b>$LOVE</b> token holders will be able to vote on anonymized{" "}
              <b>Love</b> Notes sent via the CUPID & the top 3 Winners will
              share a reward of{" "}
              <b>
                999 AVAX.{" "}
                <u className="block">Transparency | Loyality | Love</u>
              </b>
            </p>
            <p className="text-lg max-w-[500px] mx-auto my-5">
              <b>Cupid</b> & <b>Love</b> together test your relationship
              strength. Gift more $LOVE and have your Lover vote for your love
              note!
            </p>
          </div>

          <button onClick={openRewardWallet} className="px-6 py-1 bg-secondary rounded-lg text-black shadow-xl font-bold hover:scale-105 w-fit mx-auto flex gap-2 items-center mt-10">
            Verify Reward Wallet - Avascan{" "}
            <img src="/assets/logo.svg" width={20} alt="cupid-logo" />
          </button>
        </section>
      </div>
      <SuccessModal
        open={successModalOpen}
        setOpen={setSuccessModalOpen}
        extrinsicHash={blockHash}
        title="Token listed Successfully"
      />
    </>
  );
}

export default App;
