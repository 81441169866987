import React, { useState } from "react";
import { Modal, Spin } from "antd";
import styled from "styled-components";
import { useAdressContext } from "../context/AddressContext";
import { purchase } from "../service/love";
import queueNotification from "../service/queueNotification";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../service/firebase";

const ethers = require("ethers");

const StyledModal = styled(Modal)`
  .ant-modal-content {
    background-color: rgba(255, 255, 255, 0.9);
    color: black;
    border-radius: 0.8rem;
    min-width: 800px;
  }
  .ant-modal-body {
    padding-block: 1rem;
  }
`;

const SendLoveModal = ({ modalOpen, setModalOpen, setSuccessModalOpen, setBlockHash }) => {
  const { connectWallet, address, provider } = useAdressContext();

  const [loading, setLoading] = useState(false);
  const [loveAddress, setLoveAddress] = useState("");
  const [loveNote, setLoveNote] = useState("");
  const [loveCount, setLoveCount] = useState("0.1");

  const handleLoveAddressChange = (e) => {
    setLoveAddress(e.target.value);
  };

  const handleLoveNoteChange = (e) => {
    setLoveNote(e.target.value);
  };

  const sendLove = async () => {
    if (!address) {
      await connectWallet();
    }

    if (!loveAddress) {
      queueNotification({
        header: "Error",
        message: "Please provide your partner address",
        status: "error",
      });
      return;
    }

    if (!loveNote) {
      queueNotification({
        header: "Error",
        message: "Please write a love note",
        status: "error",
      });
      return;
    }

    setLoading(true);
    console.log("Sending Love", loveCount, loveNote, loveAddress, address);

    try {
      const purchaseReceipt = await purchase(provider, loveAddress, loveNote, ethers.utils.parseEther(loveCount.toString()));

      await purchaseReceipt.wait();

      const docRef = await addDoc(collection(db, "lovenote"), {
        from: address,
        to: loveAddress,
        loveNote: loveNote,
        value: loveCount
      });
      console.log("Document written with ID: ", docRef.id);

      console.log(`Transaction successful with hash: ${purchaseReceipt.hash}`);
      setBlockHash(purchaseReceipt.hash);
      setSuccessModalOpen(true);
      queueNotification({
        header: "Success",
        message: "Love Note sent successfully.",
        status: "success",
      });
    } catch (error) {
      console.log(`Transaction failed with error: ${error.message}`);
      queueNotification({
        header: "Error!",
        message: error.message,
        status: "error",
      });
    }
    setLoading(false);
  }

  return (
    <StyledModal
      title=""
      open={modalOpen}
      onOk={() => setModalOpen(false)}
      onCancel={() => setModalOpen(false)}
      footer={null}
    >
      <Spin spinning={loading}>
        <h1 className="text-4xl font-bold text-center">Send $LOVE</h1>
        <p className="text-center">
          On this Valentine, Write an immutable Love Note to your partner
        </p>
        <div className="grid grid-cols-2 gap-x-10 gap-y-5">
          <div className="col-span-2 relative">
            <div className="absolute -top-[18px] -left-[18px]">
              <img
                src="/assets/logo.svg"
                alt="love-banner"
                width={60}
                height={60}
              />
            </div>
            <textarea
              className="w-full bg-white border border-black rounded-lg px-10 py-4 mt-4"
              placeholder="Write your Note in this letter box"
              onChange={handleLoveNoteChange}
            />
          </div>
          <div className="col-span-1">
            <input
              type="text"
              id="walletAddress"
              className="w-full border border-black rounded-lg p-2"
              placeholder="Enter your Partner’s Wallet Address"
              onChange={handleLoveAddressChange}
            />
            <label htmlFor="walletAddress" className="block font-bold">
              Wallet Address
            </label>
          </div>
          <div className="col-span-1">
            <div className="flex items-center bg-white border border-black rounded-lg justify-between p-2">
              <input
                type="number"
                placeholder="How deep is your $LOVE?"
                className="border-none active:border-none focus:border-none focus:outline-none active:outline-none"
                onChange={(e) => {
                  setLoveCount(e.target.value);
                }}
              />
            </div>
            <div className="flex justify-between items-center gap-5">
              <span className="block font-bold">1 AVAX = 10000 $LOVE</span>
              {/* <span className="text-xs">Total - {total.toFixed(2)} AVAX</span> */}
            </div>
          </div>
        </div>
        <div className="flex justify-center mt-4">
          {!address ?
          <button onClick={connectWallet} className="px-6 py-1 bg-secondary rounded-lg text-black shadow-xl font-bold hover:scale-105 mx-auto flex items-center gap-2 mt-10">
            Connect Wallet
          </button> :
          <button disabled={loading} onClick={sendLove} className="px-6 py-1 bg-secondary rounded-lg text-black shadow-xl font-bold hover:scale-105 mx-auto flex items-center gap-2 mt-10">
            Activate Cupid{" "}
            <img src="/assets/logo.svg" width={20} alt="cupid-logo" />
          </button>
        }
        </div>
      </Spin>
    </StyledModal>
  );
};

export default SendLoveModal;
