const ethers = require("ethers");
const LoveMeta = require("../contracts/Love.json");
const addresses = require("../contracts/addresses.json");

export const purchase = async (provider, loveAddress, loveNote, value) => {
  const signer = await provider.getSigner();
  console.log("Signer", signer);
  console.log("LoveMeta", LoveMeta.output.abi);
  console.log("addresses.love", addresses.love);
  const contract = new ethers.Contract(addresses.love, LoveMeta.output.abi, signer);

  console.log("loveAddress", loveAddress);
  console.log("LoveNote", loveNote);
  console.log("Value", value);
  return contract.purchase(loveAddress, loveNote, { value });
};
