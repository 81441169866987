// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCrTLJk-aSP2A0JPQgIoOH8OJM7dLoFJvg",
  authDomain: "love-65f58.firebaseapp.com",
  projectId: "love-65f58",
  storageBucket: "love-65f58.appspot.com",
  messagingSenderId: "628227264317",
  appId: "1:628227264317:web:fbb7b2231ea1af1e597f54",
  measurementId: "G-ETHZELS3RP"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
