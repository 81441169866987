import { Button } from "antd";
import Address from "./Address";
import { useAdressContext } from "../context/AddressContext";

const SelectAddressFromWallet = () => {
  const { address, connectWallet } = useAdressContext();

  const requestAccount = async function () {
    console.log("requestAccount");
    await window.ethereum.request({ method: "eth_requestAccounts" });
    await connectWallet();
  };

  return (
    <>
      <Button
        type="default"
        onClick={requestAccount}
        className="color-white border border-white rounded-md text-sm px-2 py-1 hover:scale-105 shadow-lg"
      >
        {!address ? (
          "Connect Wallet"
        ) : (
          <Address address={address} />
        )}
      </Button>
    </>
  );
};
export default SelectAddressFromWallet;
